import React, { useContext, useEffect, useState } from 'react'
import cookie from 'react-cookies'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { BasePromoContext } from '../../context/BasePromoContext'
import { AssortedPromoContext } from '../../context/AssortedPromoContext'
import { BonusPromoContext } from '../../context/BonusPromoContext'
import { AssortedBonusPromoContext } from '../../context/AssortedBonusPromoContext'
import _ from 'lodash'
import { replaceAccentedCharacters } from '../../../helpers/utils'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const BRAND_PRODUCT_LIMIT = 500

let countsCache = {
  brand: {}
}

const ProductSection = ({
  promoData,
  cloningPromo,
  setCloningPromo,
  setIsSubmitDisabled,
  appliesTo,
  promoType,
  baseBrand,
  setBaseBrand,
  bonusBrand,
  setBonusBrand,
  isAssorted,
  setPromoRule,
  setPromoScaleArray,
  setCheckedMaxBonus,
  setScaleArray,
  failedCreation,
  setFailedCreation,
  setFailedCreationMessage,
  setNoValidPackages
}) => {
  const [productOptions, setProductOptions] = useState([])
  const [productsForBrands, setProductsForBrands] = useState({})
  const [baseProductSelectionType, setBaseProductSelectionType] = useState('')
  const [bonusProductSelectionType, setBonusProductSelectionType] = useState('')
  const [brandBaseProductCount, setBrandBaseProductCount] = useState('...')
  const [brandBonusProductCount, setBrandBonusProductCount] = useState('...')
  const [brandProductsLimitPassed, setBrandProductsLimitPassed] = useState(false)
  const [selectionSource, setSelectionSource] = useState(null);
  const BASE_PRODUCT = 'baseProduct'
  const BONUS_PRODUCT = 'bonusProduct'

  const {
    baseProducts,
    addBaseProduct,
    changeBasePackageProduct,
    clearBaseProduct,
    changeBaseQuantityProduct
  } = useContext(BasePromoContext)

  const {
    assortedProducts,
    addAssortedProduct,
    changeBasePackageAssortedProducts,
    clearAssortedProducts,
    removeAssortedProduct,
    addAssortedProductQuantity
  } = useContext(AssortedPromoContext)

  const {
    assortedBonusProducts,
    addAssortedBonusProduct,
    addAssortedBonusProductQuantity,
    changeBonusPackageAssortedBonusProducts,
    clearAssortedBonusProducts,
    removeAssortedBonusProduct,
    changeLimitToAssortedBonus
  } = useContext(AssortedBonusPromoContext)

  const {
    bonusProducts,
    addBonusProduct,
    removeBonusProduct,
    changeBonusPackageProduct,
    clearBonusProduct,
    changeBonusQuantityProduct,
    changeLimitToBonus
  } = useContext(BonusPromoContext)

  const isEmptyBrandBaseProductCount = brandBaseProductCount !== null && brandBaseProductCount !== undefined && Number(brandBaseProductCount) === 0  

  const mappedAssortedProducts = assortedProducts.map((p) => p.packages).sort((a, b) => a.length - b.length);
  const noValidPackages = 
    (assortedProducts.length === 0 ||(assortedProducts.length > 0 && mappedAssortedProducts[0].length === 0) || (assortedProducts.length > 0 && mappedAssortedProducts[0].every((pkg) => pkg.unit == null))) &&
    (baseProducts.length === 0 || (baseProducts.length > 0 && baseProducts[0].packages.length === 0) || (baseProducts.length > 0 && baseProducts[0].packages.every((pkg) => pkg.unit == null)))

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault()
    }
  }

  const openUrlNewWindow = (url) => {
    window.open(url, '_blank')
  }

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  const handleProductSelectTypeChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    // when these values are reset product selected
    // should be reset in order to hide and empty table
    if (event.target.name === 'applies_base_product_selection_type') {
      clearAssortedProducts()
      clearBonusProduct()
      setBrandProductsLimitPassed(false)
      setBaseBrand('')
      setBaseProductSelectionType(obj.applies_base_product_selection_type)
    } else if (event.target.name === 'applies_bonus_product_selection_type') {
      clearBonusProduct()
      setBonusBrand('')
      setBonusProductSelectionType(obj.applies_bonus_product_selection_type)
    }
    setSelectionSource('input');
  }

  const handleBrandsChange = async (event, type) => {
    if (event?.brand) {
      if (type === BASE_PRODUCT) {
        setBaseBrand(event.brand)
      } else {
        setBonusBrand(event.brand)
      }
      setSelectionSource('brand');
    }
  }

  const fetchProductsFromBrand = async (type) => {
    await fetch(
      `${BACKEND_URL}/businesses/1/products?by_brand=${baseBrand.db_ref}&non_paginated=true`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const selectedProducts = data['table']
        if (selectedProducts) {
          selectedProducts.forEach((pr, index) => {
            paramsToPostToServer(pr, type, isAssorted)
          })
        }
      })
      .catch((e) => console.log('Error al obtener productos de marca.', e))
  }

  const deleteBrandSelected = (event, type) => {
    setBrandProductsLimitPassed(false)
    clearAssortedProducts()
    setBaseBrand('')

  }

  const getProductsForBrandsData = () => {
    fetch(`${BACKEND_URL}/businesses/1/products/promo_necessary_data`, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsForBrands(data)
      })
      .catch((e) => console.log(e))
  }

  const fetchProducts = (event, type, isAssorted) => {
    event.preventDefault()
    setSelectionSource('input');
    const query = event.target.value
    if (query.length > 2) {
      fetch(`${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(query)}&productactive=true`, {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) => {
          let result = []
          if (type === BASE_PRODUCT && isAssorted) {
            // index products by "product_db_ref"
            const lookup = _.keyBy(assortedProducts, (o) => o.db_ref)

            /* find all products where "db_ref" exists in index, one loop, quick lookup. no nested loops
                return
               */
            result = _.filter(data['table'], (u) => !lookup[u.db_ref])
          } else if (type === BONUS_PRODUCT && isAssorted) {
            const lookup = _.keyBy(assortedBonusProducts, (o) => o.db_ref)

            /* find all products where "db_ref" exists in index, one loop, quick lookup. no nested loops
                return
               */
            result = _.filter(data['table'], (u) => !lookup[u.db_ref])
          } else {
            result = data['table']
          }
          setProductOptions(result)
        })
        .catch((e) => console.log(e))
    }

    const targetValue = event.target.value
    const element = document.getElementsByName(targetValue)[0]

    if (element) {
      const product_id = element.innerText
      if (product_id) {
        const selectedObj = productOptions.filter((obj) => parseInt(product_id) === obj.id)[0]
        if (selectedObj) {
          paramsToPostToServer(selectedObj, type, isAssorted)
          event.target.value = ''
        }
      }
    }
  }

  const paramsToPostToServer = (selectedObj, type, isAssorted) => {
    if (type === BASE_PRODUCT && isAssorted) {
      addAssortedProduct({
        ...selectedObj
      })
    } else if (type === BONUS_PRODUCT) {
      addBonusProduct({
        ...selectedObj
      })
    } else {
      addBaseProduct({
        ...selectedObj
      })
    }
  }

  const resetProductSelected = (event) => {
    event.preventDefault()
    clearAssortedProducts()
    clearBaseProduct()
    clearBonusProduct()
  }

  const handleBonusPackageInputChange = (event, product) => {
    const bonusPackage = event.target.value
    const getPackage = _.find(bonusProducts[0].packages, { unit: bonusPackage })
    changeBonusPackageProduct({
      bonusPackage: getPackage.unit,
      bonusPackage_uid: getPackage.uid,
      id: product.id
    })
  }

  const handleBasePackageInputChange = (event) => {
    setNoValidPackages(noValidPackages);
    if (isAssorted) {
      const basePackage = event.target.value
      changeBasePackageAssortedProducts({
        basePackage: basePackage
      })
    } else {
      const basePackage = event.target.value
      const getPackage = _.find(baseProducts[0].packages, { unit: basePackage })
      changeBasePackageProduct({
        basePackage: getPackage.unit,
        basePackage_uid: getPackage.uid,
        basePackageQuantity: getPackage.quantity,
        id: baseProducts[0].id
      })
    }
  }

  const deleteBonusProductFromTable = (event, product) => {
    removeBonusProduct(product)
    if (bonusProducts.length === 0) {
      clearBonusProducts()
    }
  }

  const deleteBaseProductFromTable = (event, product, isAssorted) => {
    if (isAssorted) {
      removeAssortedProduct(product)
      if (assortedProducts.length === 0) {
        resetProductSelected()
      }
    } else {
      resetProductSelected()
    }
  }

  const truncatePreviewText = (text) => {
    if (text?.length > 15) {
      return text.substring(0, 15) + '...'
    }
    return text
  }

  const uniqueProductsById = (products) => {
    return [...new Map(products.map((item) => [item['id'], item])).values()]
  }

  const displayBaseProductCount = () => {
    if (brandBaseProductCount == '...') {
      return 'Cargando...'
    }
    let brandCountNum = Number(brandBaseProductCount)
    if (!isNaN(brandCountNum)) {
      return `${brandCountNum} - ${(brandCountNum > 1 || brandCountNum == 0)? "PRODUCTOS ACTIVOS" : "PRODUCTO ACTIVO"}`
    }
  }

  const getBaseProductsCount = async () => {
    let type = ''
    let typeData = null
    if (baseBrand) {
      type = 'brand'
      typeData = baseBrand.db_ref
    }
    if (typeData) {
      if (countsCache[type][typeData]) {
        setBrandBaseProductCount(countsCache[type][typeData])
        if(countsCache[type][typeData] > BRAND_PRODUCT_LIMIT){
          setBrandProductsLimitPassed(true)
        }
      } else {
        setBrandBaseProductCount('...')
        let resp = await fetch(
          `${BACKEND_URL}/businesses/1/products?by_${type}=${typeData}&count_only=true`,
          {
            method: 'GET',
            signal: abortController.signal,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + cookie.load('token')
            }
          }
        )
        if (resp.status != 200) {
          console.error('Error al obtener el conteo de productos')
          return setBrandBaseProductCount(0)
        }

        let data = await resp.json()
        countsCache[type][typeData] = data.result
        setBrandBaseProductCount(data.result)
        if(data.result <= BRAND_PRODUCT_LIMIT || countsCache[type][typeData] <= BRAND_PRODUCT_LIMIT){
          fetchProductsFromBrand(BASE_PRODUCT)
        } else {
          setBrandProductsLimitPassed(true)
        }
      }
    } else {
      setBrandBaseProductCount('...')
    }
  }

  const setUpPromo = async () => {
    if (promoData) {
      switch (promoData.promo_type) {
        case 'bonus_product':
          {
            let result = []
            try {
              await fetch(
                `${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(promoData.promotion.base_product_db_ref)}&productactive=true`,
                {
                  method: 'GET',
                  signal: abortController.signal,
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + cookie.load('token')
                  }
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  result = data['table']
                })
                .catch((e) => console.log(e))
              if (result) {
                result.forEach((pr, index) => {
                  paramsToPostToServer(pr, BASE_PRODUCT, isAssorted)
                })
              }
              const getPackage = _.find(result[0].packages, {
                unit: promoData.promotion.base_package
              })
              changeBasePackageProduct({
                basePackage: getPackage.unit,
                basePackage_uid: getPackage.uid,
                basePackageQuantity: getPackage.quantity,
                id: result[0].id
              })
            } catch (e) {
              console.log('Error en duplicacion de promos (BonusProduct Base Product): ', e)
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: El producto base, requerido para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
            let bonusResult = []
            try {
              await fetch(
                `${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(promoData.promotion.bonus_product_db_ref)}&productactive=true`,
                {
                  method: 'GET',
                  signal: abortController.signal,
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + cookie.load('token')
                  }
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  bonusResult = data['table']
                })
                .catch((e) => console.log(e))
              if (bonusResult) {
                bonusResult.forEach((pr, index) => {
                  paramsToPostToServer(pr, BONUS_PRODUCT, isAssorted)
                })
              }

              const getBonusPackage = _.find(bonusResult[0].packages, {
                unit: promoData.promotion.bonus_package
              })
              changeBonusPackageProduct({
                bonusPackage: getBonusPackage.unit,
                bonusPackage_uid: getBonusPackage.uid,
                id: bonusResult[0].id
              })
            } catch (e) {
              console.log('Error en duplicacion de promos (BonusProduct Bonus product): ', e)
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: El producto bonificado, requerido para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
            try {
              switch (promoData.promotion.promo_rule_type) {
                case 'one_increment':
                  {
                    setPromoRule('one_increment')
                    const baseQuantity = promoData.promotion.base_quantity
                    changeBaseQuantityProduct({
                      baseQuantity: baseQuantity,
                      id: result[0].id
                    })
                    const bonusQuantity = promoData.promotion.bonus_quantity
                    changeBonusQuantityProduct({
                      bonusQuantity: bonusQuantity,
                      id: bonusResult[0].id
                    })
                    const maxLimit = promoData.promotion.bonus_limit
                    changeLimitToBonus({
                      maxLimit: maxLimit,
                      id: bonusResult[0].id
                    })

                    if (maxLimit > 0) {
                      setCheckedMaxBonus(true)
                    }
                  }
                  break
                case 'scale':
                  {
                    setPromoRule('scale')
                    setPromoScaleArray(promoData.promotion.promo_scale_array)
                  }
                  break
              }
            } catch (e) {
              console.log('Error en duplicacion de promos (BonusProduct Promo Rule): ', e)
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: La regla de la promoción, requerida para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
          }
          break
        case 'assorted_scale_discount_product':
          {
            let result = []
            try {
              if (promoData.promotion?.base_brand?.length > 0) {
                setBaseProductSelectionType('brands')
                let _brand = await productsForBrands?.brand?.find(
                  (b) => b.db_ref === promoData.promotion.base_brand
                )
                setBaseBrand(_brand)
                await fetch(
                  `${BACKEND_URL}/businesses/1/products?by_brand=${promoData.promotion.base_brand}&non_paginated=true`,
                  {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + cookie.load('token')
                    }
                  }
                )
                  .then((response) => response.json())
                  .then((data) => {
                    const selectedProducts = data['table']
                    result = data['table']
                    if (selectedProducts) {
                      selectedProducts.forEach((pr, index) => {
                        paramsToPostToServer(pr, BASE_PRODUCT, isAssorted)
                      })
                    }
                  })
                  .catch((e) => console.log('Error al obtener productos de marca.', e))
              } else {
                setBaseProductSelectionType('products')
                for (const productDfRef of promoData.promotion.base_product_uids) {
                  await fetch(`${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(productDfRef)}&productactive=true`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + cookie.load('token')
                    }
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      result = data['table']
                    })
                    .catch((e) => console.log(e))
                  if (result) {
                    result.forEach((pr, index) => {
                      paramsToPostToServer(pr, BASE_PRODUCT, isAssorted)
                    })
                  }
                }
              }
              let basePackage = null
              for (const unit of promoData.promotion.base_package_units) {
                let index = _.findIndex(result[0].packages, { unit: unit })
                if (index > -1) {
                  basePackage = index
                  break
                }
              }
              changeBasePackageAssortedProducts({
                basePackage: basePackage
              })
            } catch (e) {
              console.log('Error en duplicacion de promos (Discount base products): ', e)
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: Los productos base, requeridos para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
            try {
              setScaleArray(promoData.promotion.scale_discount_hash)
            } catch (e) {
              console.log('Error en duplicacion de promos (Discount promo rule): ', e)
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: La regla de promoción, requerida para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
          }
          break
        case 'assorted_bonus_product':
          {
            let result = []
            try {
              if (promoData.promotion?.base_brand?.length > 0) {
                setBaseProductSelectionType('brands')
                let _brand = await productsForBrands?.brand?.find(
                  (b) => b.db_ref === promoData.promotion.base_brand
                )
                setBaseBrand(_brand)
                await fetch(
                  `${BACKEND_URL}/businesses/1/products?by_brand=${promoData.promotion.base_brand}&non_paginated=true`,
                  {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + cookie.load('token')
                    }
                  }
                )
                  .then((response) => response.json())
                  .then((data) => {
                    const selectedProducts = data['table']
                    result = data['table']
                    if (selectedProducts) {
                      selectedProducts.forEach((pr, index) => {
                        paramsToPostToServer(pr, BASE_PRODUCT, isAssorted)
                      })
                    }
                  })
                  .catch((e) => console.log('Error al obtener productos de marca.', e))
              } else {
                setBaseProductSelectionType('products')
                for (const productDfRef of promoData.promotion.base_product_uids) {
                  await fetch(`${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(productDfRef)}&productactive=true`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + cookie.load('token')
                    }
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      result = data['table']
                    })
                    .catch((e) => console.log(e))
                  if (result) {
                    result.forEach((pr, index) => {
                      paramsToPostToServer(pr, BASE_PRODUCT, isAssorted)
                    })
                  }
                }
              }
              let basePackage = null
              for (const unit of promoData.promotion.base_package_units) {
                let index = _.findIndex(result[0].packages, { unit: unit })
                if (index > -1) {
                  basePackage = index
                  break
                }
              }
              changeBasePackageAssortedProducts({
                basePackage: basePackage
              })
            } catch (e) {
              console.log('Error en duplicacion de promos (AssortedBonusProduct base product): ', e)
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: Los productos base, requeridos para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
            let bonusResult = []
            try {
              await fetch(
                `${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(promoData.promotion.bonus_product_db_ref)}&productactive=true`,
                {
                  method: 'GET',
                  signal: abortController.signal,
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + cookie.load('token')
                  }
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  bonusResult = data['table']
                })
                .catch((e) => console.log(e))
              if (bonusResult) {
                bonusResult.forEach((pr, index) => {
                  paramsToPostToServer(pr, BONUS_PRODUCT, isAssorted)
                })
              }

              const getBonusPackage = _.find(bonusResult[0].packages, {
                unit: promoData.promotion.bonus_package
              })
              changeBonusPackageProduct({
                bonusPackage: getBonusPackage.unit,
                bonusPackage_uid: getBonusPackage.uid,
                id: bonusResult[0].id
              })
            } catch (e) {
              console.log(
                'Error en duplicacion de promos (AssortedBonusProduct bonus product): ',
                e
              )
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: Los productos bonificados, requeridos para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
            try {
              switch (promoData.promotion.promo_rule_type) {
                case 'one_increment':
                  {
                    setPromoRule('one_increment')
                    const baseQuantity = promoData.promotion.base_quantity
                    addAssortedProductQuantity({
                      baseQuantity: baseQuantity,
                      id: result[0].id
                    })
                    const bonusQuantity = promoData.promotion.bonus_quantity
                    changeBonusQuantityProduct({
                      bonusQuantity: bonusQuantity,
                      id: bonusResult[0].id
                    })
                    const maxLimit = promoData.promotion.bonus_limit
                    changeLimitToBonus({
                      maxLimit: maxLimit,
                      id: bonusResult[0].id
                    })

                    if (maxLimit > 0) {
                      setCheckedMaxBonus(true)
                    }
                  }
                  break
                case 'scale':
                  {
                    setPromoRule('scale')
                    setPromoScaleArray(promoData.promotion.promo_scale_array)
                  }
                  break
              }
            } catch (e) {
              console.log('Error en duplicacion de promos (AssortedBonusProduct promo rule): ', e)
              setFailedCreation(true)
              setFailedCreationMessage(
                'Hubo un error al intentar obtener: La regla de promoción, requerida para crear la copia de la promoción. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
              )
              setTimeout(() => setFailedCreation(false), 15000)
              setIsSubmitDisabled(false)
              return setCloningPromo(false)
            }
          }
          break
      }
      setCloningPromo(false)
      setIsSubmitDisabled(false)
    }
  }

  useEffect(() => {
    getProductsForBrandsData()
  }, [])

  useEffect(() => {
    if (promoData) {
      setUpPromo()
    }
  }, [promoData, productsForBrands.brand])

  useEffect(() => {
    setBrandProductsLimitPassed(false)
    getBaseProductsCount()
  }, [baseBrand])

  useEffect(() => {
    setNoValidPackages(noValidPackages);
  }, [noValidPackages, assortedProducts, baseProducts]);

  if (cloningPromo) {
    return null
  }
  return (
    <>
      <div className="promo-card">
        <div className="title-promos-form">
          {isAssorted ? <b>3. Producto(s) base</b> : <b>3. Producto base</b>}
        </div>
        <hr className="hr-promo-card" />

        {isAssorted && appliesTo.length === 0 && (
          <>
            <p className="promo-card-subtitle">
              3.1: Escoja la base de la promoción, por producto(s) o marca
            </p>
            <div>
              <select
                className="select-promo-type inactive"
                name="applies_base_product_selection_type"
                required
                disabled
              >
                <option value="" hidden>
                  Escoger una opcion
                </option>
              </select>
            </div>
          </>
        )}

        {isAssorted && appliesTo.length !== 0 && (
          <>
            <p className="promo-card-subtitle">
              3.1: Escoja la base de la promoción, por producto(s) o marca
            </p>
            <div>
              <select
                onChange={handleProductSelectTypeChange}
                className="select-promo-type"
                name="applies_base_product_selection_type"
                placeholder="Escoja una opción"
                value={baseProductSelectionType}
                required
              >
                <option value="" hidden>
                  {' '}
                  Escoja una opción{' '}
                </option>
                <option value="products">Producto</option>
                <option value="brands">Marcas</option>
              </select>
            </div>
          </>
        )}

        {/* Punto 3.2 Seleccion de producto base para bonificacion */}
        {isAssorted &&
          appliesTo.length !== 0 &&
          baseProductSelectionType === 'products' &&
          assortedProducts.length === 0 && (
            <>
              <p className="promo-card-subtitle mt-33">
                3.2: Escoja los producto(s) para la base de esta promoción
              </p>
              <input
                autoComplete="off"
                onChange={(e) => fetchProducts(e, BASE_PRODUCT, isAssorted)}
                onKeyPress={onKeyPress}
                className="search-input-border"
                placeholder="Escribir nombre o codigo del producto"
                list="all-products"
                required
              />
              <span className="icon-search">
                <FontAwesomeIcon className="icon-search" icon={faSearch} />
              </span>
            </>
          )}
        {isAssorted && baseProductSelectionType === 'products' && assortedProducts.length > 0 && (
          <>
            <p className="promo-card-subtitle mt-33">
              3.2: Escoja los producto(s) para la base de esta promoción
            </p>
            <input
              autoComplete="off"
              onChange={(e) => fetchProducts(e, BASE_PRODUCT, isAssorted)}
              onKeyPress={onKeyPress}
              className="search-input-border"
              placeholder="Escribir nombre o codigo del producto"
              list="all-products"
            />
            <span className="icon-search">
              <FontAwesomeIcon className="icon-search" icon={faSearch} />
            </span>
          </>
        )}
        {!isAssorted && appliesTo.length === 0 && (
          <>
            <p className="promo-card-subtitle">
              3.1: Escoja el producto para la base de esta promoción
            </p>
            <input
              autoComplete="off"
              onChange={(e) => fetchProducts(e, BASE_PRODUCT, isAssorted)}
              onKeyPress={onKeyPress}
              className="search-input-border inactive"
              placeholder="Escribir nombre o codigo del producto"
              list="all-products"
              required
              disabled
            />
            <span className="icon-search">
              <FontAwesomeIcon className="icon-search" icon={faSearch} />
            </span>
          </>
        )}
        {!isAssorted && appliesTo.length !== 0 && baseProducts.length === 0 && (
          <>
            <p className="promo-card-subtitle">
              3.1: Escoja el producto para la base de esta promoción
            </p>
            <input
              autoComplete="off"
              onChange={(e) => fetchProducts(e, BASE_PRODUCT, isAssorted)}
              onKeyPress={onKeyPress}
              className="search-input-border"
              placeholder="Escribir nombre o codigo del producto"
              list="all-products"
              required
            />
            <span className="icon-search">
              <FontAwesomeIcon className="icon-search" icon={faSearch} />
            </span>
          </>
        )}
        {!isAssorted && baseProducts.length > 0 && (
          <>
            <p className="promo-card-subtitle">
              3.1: Escoja el producto para la base de esta promoción
            </p>
            <input
              autoComplete="off"
              onChange={(e) => fetchProducts(e, BASE_PRODUCT, isAssorted)}
              onKeyPress={onKeyPress}
              className="search-input-border inactive"
              placeholder="Escribir nombre o codigo del producto"
              list="all-products"
              disabled
            />
            <span className="icon-search">
              <FontAwesomeIcon className="icon-search" icon={faSearch} />
            </span>
          </>
        )}
        <datalist id="all-products">
          {productOptions?.map((obj, index) => (
            <option
              key={index}
              id={obj.id}
              name={obj.db_ref + ' - ' + obj.name}
              value={obj.db_ref + ' - ' + obj.name}
            >
              {obj.id}
            </option>
          ))}
        </datalist>

        {isAssorted && baseProductSelectionType === 'products' && assortedProducts.length > 0 && (
          <table className="table table-hover promos-product-table">
            <thead>
              <tr>
                <th className="promo-product-table-th pl-20">CODIGO</th>
                <th className="promo-product-table-th">NOMBRE DEL PRODUCTO</th>
                <th className="promo-product-table-th">UNIDAD DE VENTA 1</th>
                <th className="promo-product-table-th">UNIDAD DE VENTA 2</th>
                <th className="promo-product-table-th">ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              {uniqueProductsById(assortedProducts).map((product, index) => (
                <tr key={`${product.id}-${index}`} style={{ background: '#f6f6f6' }}>
                  <td className="pl-20">{product.db_ref}</td>
                  <td>{product.name}</td>
                  {product.packages.map((pk) => (
                    <td key={pk.id}>
                      {' '}
                      {pk.unit} {pk.quantity > 1 ? `de ${pk.quantity}` : ''}
                    </td>
                  ))}
                  {product.packages.length == 1 ? <td> - </td> : ''}
                  {product.packages.length == 0 && (
                    <>
                      <td> - </td> <td> - </td>
                    </>
                  )}
                  <td
                    className="delete-selected-product"
                    onClick={(e) => deleteBaseProductFromTable(e, product, isAssorted)}
                  >
                    &times;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {!isAssorted && baseProducts.length > 0 && (
          <table className="table table-hover promos-product-table">
            <thead>
              <tr>
                <th className="promo-product-table-th pl-20">CODIGO</th>
                <th className="promo-product-table-th">NOMBRE DEL PRODUCTO</th>
                <th className="promo-product-table-th">UNIDAD DE VENTA 1</th>
                <th className="promo-product-table-th">UNIDAD DE VENTA 2</th>
                <th className="promo-product-table-th">ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              {uniqueProductsById(baseProducts).map((product, index) => (
                <tr key={`${product.id}-${index}`}>
                  <td className="pl-20">{product.db_ref}</td>
                  <td>{product.name}</td>
                  {product.packages.map((pk) => (
                    <td key={pk.id}>
                      {' '}
                      {pk.unit} {pk.quantity > 1 ? `de ${pk.quantity}` : ''}
                    </td>
                  ))}
                  {product.packages.length == 1 ? <td> - </td> : ''}
                  {product.packages.length == 0 && (
                    <>
                      <td> - </td> <td> - </td>
                    </>
                  )}
                  <td className="delete-selected-product" onClick={(e) => resetProductSelected(e)}>
                    &times;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Punto 3.2 Seleccion de marca base para bonificacion */}
        {appliesTo.length !== 0 && baseProductSelectionType === 'brands' && isAssorted && (
          <>
            {!baseBrand && (
              <>
                <p className="promo-card-subtitle mt-33">
                  3.2: Escoja la marca para la base de esta promoción
                </p>
                <div className="form-control-width">
                  <div className="fake-validator-container">
                    <input
                      className="input-fake-validator"
                      type="text"
                      required
                      value={baseBrand?.db_ref ? baseBrand?.db_ref : null}
                    />
                    <Select
                      placeholder={'Escoja una opcion'}
                      noOptionsMessage={() => 'No hay resultados'}
                      loadingMessage={() => 'Cargando...'}
                      className="brand-select"
                      isClearable={false}
                      value={baseBrand ? { value: baseBrand.db_ref, label: baseBrand.name } : null}
                      onChange={(e) => handleBrandsChange(e, BASE_PRODUCT)}
                      options={productsForBrands?.brand?.map((option) => {
                        return { value: option.db_ref, label: option.name, brand: option }
                      })}
                    />
                  </div>
                </div>
              </>
            )}
            {baseBrand && (
              <>
                <p className="promo-card-subtitle mt-33">
                  3.2: Escoja la marca para la base de esta promoción
                </p>
                <div className="form-control-width">
                  <div className="fake-validator-container">
                    <input
                      className="input-fake-validator"
                      type="text"
                      disabled
                      value={baseBrand?.db_ref ? baseBrand?.db_ref : null}
                    />
                    <Select
                      placeholder={'Escoja una opcion'}
                      noOptionsMessage={() => 'No hay resultados'}
                      loadingMessage={() => 'Cargando...'}
                      className="brand-select"
                      isClearable={false}
                      isDisabled={true}
                      value={baseBrand ? { value: baseBrand.db_ref, label: baseBrand.name } : null}
                      onChange={(e) => handleBrandsChange(e, BASE_PRODUCT)}
                      options={productsForBrands?.brand?.map((option) => {
                        return { value: option.db_ref, label: option.name, brand: option }
                      })}
                    />
                  </div>
                </div>
              </>
            )}
            {baseBrand?.db_ref && (
              <div className="box-products-preview-container">
                <div className="box-products-preview mr-10">
                  <p style={{ margin: 0 }} title={baseBrand?.name}>
                     {displayBaseProductCount()}
                  </p>
                  <div
                    onClick={(e) => deleteBrandSelected(e, BASE_PRODUCT)}
                    className="delete-selected-product"
                  >
                    &times;
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    openUrlNewWindow(
                      `/productos?by_brand=${baseBrand.db_ref}&brand_name=${baseBrand.name}`
                    )
                  }
                  className="big-btn"
                >
                  Ver listado de productos
                </button>
              </div>
            )}
            {baseBrand?.db_ref && brandProductsLimitPassed && (
              <p className="warning-brand-product-limit-text">
                No es posible crear una promoción con marcas que tienen más de 500 productos. Elige una marca diferente con menos productos para continuar.
              </p>
            )}
          </>
        )}

        <div>
          {isEmptyBrandBaseProductCount && (
            <>
              {baseProductSelectionType === 'brands' ? (
                <p className="promo-card-subtitle mt-60">3.3: Seleccione la unidad de venta</p>
              ) : (
                <p className="promo-card-subtitle mt-33">3.2: Seleccione la unidad de venta</p>
              )}
              <div>
                <select
                  className={`select-promo-type ${
                    assortedProducts.length === 0 && baseProducts.length === 0
                      ? 'select-disabled-promo'
                      : ''
                  }`}
                  name="base_package_promo"
                  value={
                    isAssorted
                      ? _.findIndex(assortedProducts[0]?.packages, {
                          unit: assortedProducts[0]?.base_package
                        })
                      : baseProducts[0]?.base_package
                  }
                  required
                  disabled
                >
                  <option value="" hidden>
                    Escoja una opción
                  </option>
                </select>
              </div>

              {noValidPackages && selectionSource === 'brand' && (
              <p className="warning-text-promo">
                La marca seleccionada tiene uno o más productos sin empaques disponibles. Elige una
                marca diferente para continuar.
              </p>
            )}
            {(noValidPackages && selectionSource === 'input') && (
              <p className="warning-text-promo">
                Los productos seleccionados carecen de paquetes necesarios.
              </p>
            )}
          </>
          )}
        </div>

        {(assortedProducts.length > 0 || baseProducts.length > 0) && (
          <>
            {baseProductSelectionType === 'brands' ? (
              <p className="promo-card-subtitle mt-60">3.3: Seleccione la unidad de venta</p>
            ) : (
              <p className="promo-card-subtitle mt-33">3.2: Seleccione la unidad de venta</p>
            )}
            <div>
              <select
                onChange={(e) => handleBasePackageInputChange(e)}
                className={`select-promo-type ${
                  assortedProducts.length === 0 && baseProducts.length === 0
                    ? 'select-disabled-promo'
                    : ''
                }`}
                name="base_package_promo"
                value={
                  isAssorted
                    ? _.findIndex(assortedProducts[0]?.packages, {
                        unit: assortedProducts[0]?.base_package
                      })
                    : baseProducts[0]?.base_package
                }
                required
                disabled={noValidPackages}
              >
                <option value="" hidden>
                  Escoja una opción
                </option>
                {assortedProducts.length > 0 &&
                  assortedProducts
                    .map((p) => p.packages)
                    .sort(function (a, b) {
                      return a.length - b.length
                    })[0]
                    .map((obj, index) => (
                      <option key={obj.id} id={obj.id} name={obj.unit} value={index}>
                        {`Unidad de venta ${index + 1}`}
                      </option>
                    ))}
                {baseProducts.length > 0 &&
                  baseProducts[0]?.packages.map((obj, index) => (
                    <option key={obj.id} id={obj.id} name={obj.unit} value={obj.unit}>
                      {`Unidad de venta ${index + 1}`}
                    </option>
                  ))}
              </select>
            </div>
            {noValidPackages && selectionSource === 'brand' && (
              <p className="warning-text-promo">
                La marca seleccionada tiene uno o más productos sin empaques disponibles. Elige una
                marca diferente para continuar.
              </p>
            )}
            {(noValidPackages && selectionSource === 'input') && (
              <p className="warning-text-promo">
                Los productos seleccionados carecen de paquetes necesarios.
              </p>
            )}
          </>
        )}
      </div>

      {promoType != 'assorted_scale_discount_product' && (
        <div className="promo-card">
          <div className="title-promos-form">
            {isAssorted ? <b>4. Producto(s) bonificado(s)</b> : <b>4. Producto bonificado</b>}
          </div>
          <hr className="hr-promo-card" />
          {assortedProducts.length === 0 && baseProducts.length === 0 && (
            <>
              <p className="promo-card-subtitle">
                4.1: Escoja el producto a bonificar para esta promoción
              </p>
              <input
                autoComplete="off"
                onChange={fetchProducts}
                onKeyPress={onKeyPress}
                className="search-input-border inactive"
                list="all-products"
                placeholder="Escribir nombre o codigo del producto"
                required
                disabled
              />
              <span className="icon-search">
                <FontAwesomeIcon className="icon-search " icon={faSearch} />
              </span>
            </>
          )}
          {(assortedProducts.length > 0 || baseProducts.length > 0) &&
            bonusProducts.length === 0 && (
              <>
                <p className="promo-card-subtitle">
                  4.1: Escoja el producto a bonificar para esta promoción
                </p>
                <input
                  autoComplete="off"
                  onChange={(e) => fetchProducts(e, BONUS_PRODUCT, isAssorted)}
                  onKeyPress={onKeyPress}
                  className="search-input-border"
                  placeholder="Escribir nombre o codigo del producto"
                  list="all-products"
                  required
                />
                <span className="icon-search">
                  <FontAwesomeIcon className="icon-search" icon={faSearch} />
                </span>
              </>
            )}
          {bonusProducts.length > 0 && (
            <>
              <p className="promo-card-subtitle">
                4.1: Escoja el producto a bonificar para esta promoción
              </p>
              <input
                autoComplete="off"
                onChange={(e) => fetchProducts(e, BONUS_PRODUCT, isAssorted)}
                onKeyPress={onKeyPress}
                className="search-input-border inactive"
                placeholder="Escribir nombre o codigo del producto"
                list="all-products"
                disabled
              />
              <span className="icon-search">
                <FontAwesomeIcon className="icon-search" icon={faSearch} />
              </span>
            </>
          )}
          <datalist id="all-products">
            {productOptions?.map((obj, index) => (
              <option
                key={index}
                id={obj.id}
                name={obj.db_ref + ' - ' + obj.name}
                value={obj.db_ref + ' - ' + obj.name}
              >
                {obj.id}
              </option>
            ))}
          </datalist>

          {bonusProducts.length > 0 && (
            <table className="table table-hover promos-product-table">
              <thead>
                <tr>
                  <th className="promo-product-table-th pl-20">CODIGO</th>
                  <th className="promo-product-table-th">NOMBRE DEL PRODUCTO</th>
                  <th className="promo-product-table-th">UNIDAD DE VENTA 1</th>
                  <th className="promo-product-table-th">UNIDAD DE VENTA 2</th>
                  <th className="promo-product-table-th">ACCIÓN</th>
                </tr>
              </thead>
              <tbody>
                {uniqueProductsById(bonusProducts).map((product, index) => (
                  <tr key={`${product.id}-${index}`} style={{ background: '#f6f6f6' }}>
                    <td className="pl-20">{product.db_ref}</td>
                    <td>{product.name}</td>
                    {product.packages.map((pk) => (
                      <td key={pk.id}>
                        {' '}
                        {pk.unit} {pk.quantity > 1 ? `de ${pk.quantity}` : ''}
                      </td>
                    ))}
                    {product.packages.length == 1 ? <td> - </td> : ''}
                    {product.packages.length == 0 && (
                      <>
                        <td> - </td> <td> - </td>
                      </>
                    )}
                    <td
                      className="delete-selected-product"
                      onClick={(e) => deleteBonusProductFromTable(e, bonusProducts[0])}
                    >
                      &times;
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {bonusProducts.length > 0 && (
            <>
              <p className="promo-card-subtitle mt-33">4.2: Seleccione la unidad de venta</p>
              <div>
                <select
                  onChange={(e) => handleBonusPackageInputChange(e, bonusProducts[0])}
                  className="select-promo-type"
                  name="bonus_package_promo"
                  value={bonusProducts[0].bonus_package}
                  required
                >
                  <option value="" hidden>
                    Escoja una opción
                  </option>
                  {bonusProducts[0]?.packages.map((obj, index) => (
                    <option key={obj.id} id={obj.id} name={obj.unit} value={obj.unit}>
                      {`Unidad de venta ${index + 1}`}
                    </option>
                  ))}
                </select>
              </div>
              {bonusProducts[0]?.packages.length === 0 && (
      <p className="warning-text-promo">
        Los productos seleccionados carecen de paquetes necesarios.
      </p>
    )}
            </>
          )}
        </div>
      )}
    </>
  )
}
export default ProductSection
