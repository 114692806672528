import React, { useState, useContext, useEffect } from 'react'
import Pagination from '../layout/Pagination'
import ActionButtonProvider from '../components/action_button/ActionButtonContext'
import DropdownActionButton from '../components/action_button/DropdownActionButton'
import SeeMoreButton from '../components/action_button/SeeMoreButton'
import cookie from 'react-cookies'
import DatePicker from 'react-multi-date-picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilter'
import ReactMultipleFilter from '../components/inputs/ReactMultipleFilter'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import { MetroSpinner } from 'react-spinners-kit'
import moment from 'moment'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const PromoIndexView = ({
  promoData,
  filterData,
  promoProductsData,
  pagination,
  handlePaginationClick,
  dateQuery,
  searchQuery,
  filterQuery,
  setDateQuery,
  setSearchQuery,
  setFilterQuery,
  isFetching,
  isFetchingFilter,
  title,
  successCreateForNewPromo,
  collapsed,
  clientQualifierDic,
  setPagination
}) => {
  const [alertState, setAlertState] = useState('alert alert-success')
  const [searchInput, setSearchInput] = useState('')
  const [filters, setFilters] = useState(new Map())
  const [filtersDic, setFiltersDic] = useState({
    Estado: 'status',
    'Tipo de promoción': 'promo_type',
    'PRODUCTO(S)/MARCA': 'products',
    'Cliente individual': 'qualifier_1',
    'Atributo A': 'qualifier_2',
    'Atributo B': 'qualifier_3',
    'Atributo C': 'qualifier_4'
  })
  const [statusDic, setStatusDic] = useState({
    Activas: 'active',
    'No iniciadas': 'scheduled',
    Suspendidas: 'suspended',
    Caducadas: 'expired'
  })
  const [promoTypeDic, setPromoTypeDic] = useState({
    'Bonificación de una referencia': 'bonus_product',
    'Bonificación surtida': 'assorted_bonus_product',
    'Descuento de una referencia / Descuento surtido': 'assorted_scale_discount_product'
  })
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [allSelectedOptions, setAllSelectedOptions] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)

  useEffect(() => {
    buildFiltersQuery()
  }, [allSelectedOptions])

  useEffect(() => {
    handleFilterData()
  }, [promoProductsData])

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      buildSearchQuery()
    }
  }

  const getTimestampFromDateTime = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  const showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open('/promociones/' + id)
  }

  const redirectToCreatePromoPage = () => {
    window.location = '/nueva_promo'
  }

  const hiddenAlert = () => {
    setAlertState('alert alert-success hidden')
  }

  const handleSuspendPromo = (model, event) => {
    var response = window.confirm('Desea continuar con la suspension de la promoción?')
    if (response === true) {
      fetch(`${BACKEND_URL}/promos/${model['uuid']}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((data) => (window.location = `/promociones/${model['uuid']}`))
        .catch((e) => console.log(e))
    }
  }

  const handleCreatePromoCopy = (model, event) => {
    var response = window.confirm('¿Está seguro de crear una copia a partir de esta promoción?')
    if (response === true) {
      window.location = `/nueva_promo?clone=${model['uuid']}`
    }
  }

  const handleProductCount = (products) => {
    return products.reduce((totalLength, product) => {
      return totalLength + `${product['db_ref']} - ${product['name']}`.length
    }, 0)
  }

  const buildSearchQuery = () => {
    let queryParam = ''
    if (searchInput) {
      queryParam = `&q=${searchInput}`
    }
    setSearchQuery(queryParam)
    setPagination((prev) => ({ ...prev, current_page: 1 }))
  }

  const buildFiltersQuery = () => {
    if (filterData) {
      let queryParam = ''
      let statusArray = []
      let promoTypeArray = []
      let clientArray = []
      let productArray = []
      let filterClients = filterData['clients']
      let filterProducts = filterData['products']
      if (allSelectedOptions) {
        for (const [key, value] of allSelectedOptions) {
          if (value.length != 0) {
            if (key == 'Estado') {
              value.map((status) => {
                statusArray.push(statusDic[status])
              })
              queryParam += `&${filtersDic[key]}=${statusArray.join(',')}`
            } else if (key == 'Tipo de promoción') {
              value.map((type) => {
                promoTypeArray.push(promoTypeDic[type])
              })
              queryParam += `&${filtersDic[key]}=${promoTypeArray.join(',')}`
            } else if (key == 'Cliente individual') {
              value.map((v) => {
                let clientData = filterClients.find((c) => c.name == v)
                clientArray.push(clientData['db_ref'])
              })
              queryParam += `&${filtersDic[key]}=${clientArray.join(',')}`
            } else if (key == 'PRODUCTO(S)/MARCA') {
              value.map((v) => {
                let productData = filterProducts.find((c) => c.name == v)
                productArray.push(productData['id'])
              })
              queryParam += `&${filtersDic[key]}=${productArray.join(',')}`
            } else {
              queryParam += `&${filtersDic[key]}=${value.join(',')}`
            }
          }
        }
        setFilterQuery(queryParam)
        setPagination((prev) => ({ ...prev, current_page: 1 }))
      }
    }
  }

  const handleFilterData = async () => {
    let availableFilters = [
      { key: 'Estado', value: ['Activas', 'No iniciadas', 'Suspendidas', 'Caducadas'] },
      {
        key: 'Tipo de promoción',
        value: [
          'Bonificación de una referencia',
          'Bonificación surtida',
          'Descuento de una referencia / Descuento surtido'
        ]
      },
      { key: 'PRODUCTO(S)/MARCA', value: [] }
    ]

    availableFilters[2].value = promoProductsData
    setFilters(availableFilters)
  }

  const handleDateFilter = (datesObj) => {
    let formatedDates = []
    let queryParam = ''
    let startDate = new Date()
    let endDate = new Date()
    if (!datesObj || datesObj.length == 0) {
      setDateQuery(null)
    } else if (datesObj) {
      datesObj.map((date) => {
        formatedDates.push(new Date(date.unix * 1000))
      })
      //When there is a date range
      if (formatedDates.length > 1) {
        if (formatedDates[0] < formatedDates[1]) {
          startDate = new Date(formatedDates[0])
          endDate = new Date(formatedDates[1])
        } else if (formatedDates[0] > formatedDates[1]) {
          startDate = new Date(formatedDates[1])
          endDate = new Date(formatedDates[0])
        }
      }
      //When is only one date
      else if (formatedDates.length == 1) {
        startDate = new Date(formatedDates[0])
        endDate = new Date(formatedDates[0])
      }
      queryParam = `&start_date=${startDate.toLocaleDateString()}&end_date=${endDate.toLocaleDateString()}`
      setDateQuery(queryParam)
      setPagination((prev) => ({ ...prev, current_page: 1 }))
    }
  }

  function getStatusPromo(status) {
    switch (status) {
      case 'active':
        return 'Activa'
      case 'expired':
        return 'Caducada'
      case 'scheduled':
        return 'No Iniciada'
      case 'suspended':
        return 'Suspendida'
      default:
        return 'Desconocido'
    }
  }

  const resetFilters = () => {
    setAllSelectedOptions(null)
    setSearchInput('')
    setFilterQuery(null)
    setDateQuery(null)
    setSelectedDates(null)
    setResetReactFilters(!resetReactFilters)
  }

  const data = promoData
  return (
    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
      {successCreateForNewPromo && (
        <div className={alertState} role="alert">
          <button type="button" className="close" onClick={hiddenAlert} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <strong className="success-message">La promoción nueva fue creada exitosamente.</strong>
        </div>
      )}
      <div>
        <div className="index-header detailed-container">
          <h2>{title}</h2>
          <div className="index-buttons">
            <button onClick={redirectToCreatePromoPage}>Crear promoción nueva</button>
          </div>
        </div>
      </div>
      <div className="index-table">
        <div className="show-area">
          <h4 className="">Filtrar promociones por:</h4>
          <div className="filters-search-container mb-10">
            <FontAwesomeIcon className="filter-icon mr-10 " icon={faFilter} />
            <div className="filters-container">
              {Array.from(filters).map((filter, indx) => (
                <div key={`flr-${indx}`} className="mr-10">
                  <ReactFilter
                    className="mr-10"
                    title={filter.key}
                    identifier={filter.key}
                    options={filter.value}
                    disabled={isFetchingFilter || data.length === 0}
                    resetWatcher={resetReactFilters}
                    onChange={(e) => {
                      let so = new Map(allSelectedOptions)
                      so.set(e.identifier, e.selectedOptions)
                      setAllSelectedOptions(so)
                    }}
                  />
                </div>
              ))}
              <div key={`mul-flr-clients`} className="mr-10">
                <ReactMultipleFilter
                  className="mr-10"
                  title={'Cliente/Atributo'}
                  optionsKeys={['Cliente individual', 'Atributo A', 'Atributo B', 'Atributo C']}
                  optionsDic={clientQualifierDic}
                  resetWatcher={resetReactFilters}
                  allSelectedOptions={allSelectedOptions}
                  disabled={isFetchingFilter || data.length === 0}
                  onChange={(newAllSelectedOptions) => {
                    setAllSelectedOptions(newAllSelectedOptions)
                  }}
                />
              </div>
              <DatePicker
                range
                rangeHover
                dateSeparator=" - "
                months={[
                  'Enero',
                  'Febrero',
                  'Marzo',
                  'Abril',
                  'Mayo',
                  'Junio',
                  'Julio',
                  'Agosto',
                  'Septiembre',
                  'Octubre',
                  'Noviembre',
                  'Diciembre'
                ]}
                weekDays={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                format="DD MMM"
                weekStartDayIndex={1}
                highlightToday={false}
                numberOfMonths={2}
                maxDate={moment().local().endOf('day').toDate()}
                value={selectedDates}
                disabled={isFetchingFilter || data.length === 0}
                onChange={(dateObjects) => {
                  handleDateFilter(dateObjects)
                  setSelectedDates(dateObjects)
                }}
                plugins={[
                  <Toolbar
                    position="bottom"
                    names={{
                      deselect: 'Deseleccionar'
                    }}
                    sort={['deselect']}
                  />
                ]}
                render={(value, openCalendar) => {
                  return (
                    <div
                      key={`flr-date`}
                      className={`mr-10 ${
                        isFetchingFilter || data.length === 0 ? 'react-filter-disabled' : ''
                      }`}
                    >
                      <div className="react-filter-box filter-input-width-datepicker">
                        <div
                          title="Rango de fechas"
                          onClick={
                            isFetchingFilter || !data || data.length === 0
                              ? undefined
                              : openCalendar
                          }
                          className="react-filter-box-clickable-zone"
                        >
                          <div className="react-filter-titles">
                            {value?.length > 0 && (
                              <>
                                {Array.isArray(value) ? (
                                  <div className="filter-title-overflow">{value.join(' - ')}</div>
                                ) : (
                                  <div className="filter-title-overflow">{value}</div>
                                )}
                                <FontAwesomeIcon
                                  style={{ color: isFetchingFilter ? '#D5D5D5' : 'ef823a' }}
                                  icon={faCalendar}
                                />
                              </>
                            )}
                            {value?.length == 0 && (
                              <>
                                <div className="filter-title-overflow">RANGO DE FECHAS</div>
                                <FontAwesomeIcon
                                  style={{
                                    color:
                                      isFetchingFilter || data.length === 0 ? '#D5D5D5' : '#597fa9'
                                  }}
                                  icon={faCalendar}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }}
              />
            </div>
            <div className="search-input-containter">
              <input
                value={searchInput}
                onChange={handleSearch}
                onKeyDown={handleEnterSearch}
                className="round-search-input"
                style={{ fontSize: 12 }}
                placeholder="Buscar promociones por código o descripción"
              />
              {searchInput === '' && (
                <span className="glyphicon glyphicon-search form-control-feedback" />
              )}
              {searchInput && (
                <div
                  title="Limpiar texto"
                  onClick={() => {
                    setSearchInput('')
                    setSearchQuery('')
                  }}
                  className="round-search-input-x"
                >
                  X
                </div>
              )}
            </div>
          </div>
          <div className="mb-20">
            {(filterQuery || dateQuery) && (
              <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
                Restablecer filtros aplicados
              </a>
            )}
          </div>
          <div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ verticalAlign: 'middle', width: '8%' }}>
                    <span className="th-header-text">FECHA Y HORA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '8%' }}>
                    <span className="th-header-text">CODIGO DE PROMOCIÓN</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '8%' }}>
                    <span className="th-header-text-code-promo">ESTADO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '15%' }}>
                    <span className="th-header-text">DESCRIPCIÓN</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '15%' }}>
                    <span className="th-header-text">CLIENTE / ATRIBUTO(S)</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '25%' }}>
                    <span className="th-header-text">PRODUCTO(S)/MARCA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', width: '15%' }}>
                    <span className="th-header-text">PROMOCIÓN</span>
                  </th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'left', width: '10%' }}>
                    <span className="th-header-text-end-promo">ACCIÓN</span>
                  </th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  <ActionButtonProvider>
                    {data.length > 0 &&
                      data.map((model, index) => (
                        <tr className="cursor-pointer" key={index}>
                          <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                            {getTimestampFromDateTime(model['created_at'])}
                          </td>
                          <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                            {model['external_promo_db_ref']}
                          </td>
                          <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                            {getStatusPromo(model['status'])}
                          </td>
                          <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                            {model.description}
                          </td>
                          {model['applies_to_type'] === 'client' && (
                            <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                              {`${model['applies_to_clients'][0]['db_ref']} - ${model['applies_to_clients'][0]['name']}`}
                            </td>
                          )}
                          {model['applies_to_type'] === 'qualifier' &&
                            model['qualifier_2'] !== null &&
                            model['qualifier_3'] !== null &&
                            model['qualifier_4'] !== null && (
                              <td
                                className="td-text-limited"
                                id={model['uuid']}
                                onClick={showModel}
                                style={{
                                  height: 'auto',
                                  overflowWrap: 'break-word',
                                  inlineSize: 350
                                }}
                              >
                                Atributo A - {model['qualifier_2'].join(', ')} <br />
                                Atributo B - {model['qualifier_3'].join(', ')} <br />
                                Atributo C - {model['qualifier_4'].join(', ')}
                              </td>
                            )}
                          {model['applies_to_type'] === 'qualifier' &&
                            model['qualifier_2'] !== null &&
                            model['qualifier_3'] !== null &&
                            model['qualifier_4'] === null && (
                              <td
                                className="td-text-limited"
                                id={model['uuid']}
                                onClick={showModel}
                                style={{
                                  height: 'auto',
                                  overflowWrap: 'break-word',
                                  inlineSize: 350
                                }}
                              >
                                Atributo A - {model['qualifier_2'].join(', ')} <br />
                                Atributo B - {model['qualifier_3'].join(', ')}
                              </td>
                            )}
                          {model['applies_to_type'] === 'qualifier' &&
                            model['qualifier_2'] !== null &&
                            model['qualifier_3'] === null &&
                            model['qualifier_4'] !== null && (
                              <td
                                className="td-text-limited"
                                id={model['uuid']}
                                onClick={showModel}
                                style={{
                                  height: 'auto',
                                  overflowWrap: 'break-word',
                                  inlineSize: 350
                                }}
                              >
                                Atributo A - {model['qualifier_2'].join(', ')} <br />
                                <br />
                                Atributo C - {model['qualifier_4'].join(', ')}
                              </td>
                            )}
                          {model['applies_to_type'] === 'qualifier' &&
                            model['qualifier_2'] === null &&
                            model['qualifier_3'] !== null &&
                            model['qualifier_4'] !== null && (
                              <td
                                className="td-text-limited"
                                id={model['uuid']}
                                onClick={showModel}
                                style={{
                                  height: 'auto',
                                  overflowWrap: 'break-word',
                                  inlineSize: 350
                                }}
                              >
                                Atributo B - {model['qualifier_3'].join(', ')} <br />
                                <br />
                                Atributo C - {model['qualifier_4'].join(', ')}
                              </td>
                            )}
                          {model['applies_to_type'] === 'qualifier' &&
                            model['qualifier_2'] !== null &&
                            model['qualifier_3'] === null &&
                            model['qualifier_4'] === null && (
                              <td
                                className="td-text-limited"
                                id={model['uuid']}
                                onClick={showModel}
                                style={{
                                  height: 'auto',
                                  overflowWrap: 'break-word',
                                  inlineSize: 350
                                }}
                              >
                                Atributo A - {model['qualifier_2'].join(', ')} <br />
                              </td>
                            )}
                          {model['applies_to_type'] === 'qualifier' &&
                            model['qualifier_2'] === null &&
                            model['qualifier_3'] !== null &&
                            model['qualifier_4'] === null && (
                              <td
                                className="td-text-limited"
                                id={model['uuid']}
                                onClick={showModel}
                                style={{
                                  height: 'auto',
                                  overflowWrap: 'break-word',
                                  inlineSize: 350
                                }}
                              >
                                Atributo B - {model['qualifier_3'].join(', ')} <br />
                              </td>
                            )}
                          {model['applies_to_type'] === 'qualifier' &&
                            model['qualifier_2'] === null &&
                            model['qualifier_3'] === null &&
                            model['qualifier_4'] !== null && (
                              <td
                                className="td-text-limited"
                                id={model['uuid']}
                                onClick={showModel}
                                style={{
                                  height: 'auto',
                                  overflowWrap: 'break-word',
                                  inlineSize: 350
                                }}
                              >
                                Atributo C - {model['qualifier_4'].join(', ')}
                              </td>
                            )}
                          <td id={model['uuid']} onClick={showModel}>
                            {model['promo_details'] && model['promo_details'].length > 0 ? (
                              handleProductCount(model['promo_details']) > 100 ? (
                                <SeeMoreButton
                                  id={`see-more-btn-${model['uuid']}`}
                                  products={model['promo_details']}
                                  uuid={model['uuid']}
                                />
                              ) : (
                                model['promo_details'].map((product, index) => (
                                  <div id={model['uuid']} onClick={showModel} key={index}>
                                    {product['db_ref'] + ' - ' + product['name']}
                                  </div>
                                ))
                              )
                            ) : (
                              <span>N/A</span>
                            )}
                          </td>
                          {model['promo_type'] === 'bonus_product' && (
                            <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                              Bonificación de una referencia
                            </td>
                          )}
                          {model['promo_type'] === 'assorted_bonus_product' && (
                            <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                              Bonificación surtida
                            </td>
                          )}
                          {(model['promo_type'] === 'discount' ||
                            model['promo_type'] === 'assorted_scale_discount_product' ||
                            model['promo_type'] === 'scale_prices_reduction') && (
                            <td className="td-text-limited" id={model['uuid']} onClick={showModel}>
                              Descuento de una referencia / Descuento surtido
                            </td>
                          )}
                          <td className="td-text-limited">
                            <DropdownActionButton
                              id={`btn-dropdown-${model['uuid']}`}
                              list={[
                                {
                                  disabled: model['status'] === 'suspended',
                                  text: 'Suspender',
                                  handler: (e) => {
                                    handleSuspendPromo(model, e)
                                  }
                                },
                                {
                                  text: 'Crear copia',
                                  handler: (e) => {
                                    handleCreatePromoCopy(model, e)
                                  }
                                }
                              ]}
                            />
                          </td>
                        </tr>
                      ))}
                  </ActionButtonProvider>
                  {!isFetching && data.length === 0 && (
                    <tr>
                      <td>No hubo resultados</td>
                    </tr>
                  )}
                </tbody>
              )}
              {isFetching && (
                <tr>
                  <td colSpan="8" className="td-centered">
                    <div className="loading-box">
                      <MetroSpinner size={25} color="#747474" />
                      <span style={{ marginLeft: '10px', color: '#747474', fontSize: '14px' }}>
                        Actualizando
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </table>
            {pagination.total_pages > 1 && (
              <div onClick={handlePaginationClick}>
                <Pagination
                  current_page={pagination.current_page}
                  total_pages={pagination.total_pages}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
  // }
}
export default PromoIndexView
